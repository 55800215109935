










































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import * as UserApi from "@/api/users";
import { PageNum } from "@/tool/interface-index";
import * as consts from "@/utils/public-consts";
import { User } from "@/tool/_class";
import dayjs from "dayjs";
import { handleClipboard, clipboardSuccess, clipboardError } from "@/utils/clipboard"; // use clipboard directly
import Clipboard from 'clipboard';
import { repeat } from "lodash";

class UserListItem extends User {
  authSchools?: {
    schoolId: string;
    schoolName: string;
    userSchoolAuthId: string;
  }[];
}



@Component({
  name: "SystemUserListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {


  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private query = {
    keywords: ""
  }
  private loadingUserList: boolean = false;
  private tableData: Array<UserListItem> = [];


  private loadingUpgradePassword: boolean = false;
  private async doUpgradePasswordClick() {
    try {
      this.loadingUpgradePassword = true;
      await UserApi.upgradePassword({});
      await this.refreshTableData();
    } finally {
      this.loadingUpgradePassword = false;
    }
  }

  private editSystemUser(userId: string, actionType: string) {
    this.$router.push({
      path: "systemUserEditor",
      query: {
        userId: userId,
        type: actionType,
      },
    });
  }

  private authSchoolNamesFormatter(
    row: UserListItem,
    column: any,
    value: string[],
    index: number
  ) {
    if (row.authSchools && row.authSchools.length) {
      if (row.authSchools.length == 1) {
        return row.authSchools.map((item) => item.schoolName).toLocaleString();
      }
      return `共授权${row.authSchools.length}所学校`
    }
    return "(无)";
  }

  private roleIdFormatter(
    row: UserListItem,
    column: any,
    value: string[],
    index: number
  ) {
    let roleNames: string[] = [];
    if (row.roles) {
      for (let i = 0; i < row.roles.length; i++) {
        consts.roles.find((element: any) => {
          const compared =
            element.roleId.toLowerCase() == row.roles[i].toLowerCase();
          if (compared) {
            //roleNames.push(`<el-tag>${element.roleName}</el-tag>`)
            roleNames.push(`${element.roleName}`);
          }
          return compared;
        });
      }
    }

    return roleNames.toLocaleString() || "(无)";
  }

  private psdValidFormatter(
    row: UserListItem,
    column: any,
    value: string[],
    index: number
  ) {
    if (!row.psdValidDays) {
      return "首次登陆需改密码"
    }
    return dayjs(row.psdLastChgTime).add(row.psdValidDays, "day").format("YYYY-MM-DD");
    return "不限"
  }

  private upDataPage() {
    this.refreshTableData();
  }
  private async deleteSystemUser(userId: string) {
    await this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });

    await UserApi.delUser(userId);

    this.$message({
      message: "删除成功",
      type: "success",
    });

    this.refreshTableData();
  }

  doUserSchoolAuthClick(userId: string) {
    this.$router.push({
      path: "userSchoolAuth",
      query: {
        userIds: [userId],
        userSchoolAuthTo: "right",
      },
    });
  }

  async doResetPasswordClick(userId: string, event: MouseEvent) {
    const target = event.target;

    await this.$confirm("重置密码, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });

    let msg: string = "";
    let bb: boolean = false;
    while (true) {
      const { data } = await UserApi.resetPassword({ userId });
      msg = `帐号：${data.username}，密码：${data.password}\r\n\r\n注意：首次使用需修改密码才能使用。`;
      bb = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(data.password);
      if (bb) {
        break;
      }
    }
    /*
    const clipboard = new Clipboard(target as Element, {
      text: () => msg
    })
    clipboard.on('success', () => {
      clipboardSuccess()
      clipboard.destroy()
    })
    clipboard.on('error', () => {
      clipboardError()
      clipboard.destroy()
    });
    (clipboard as any).onClick(event)
    */
    //
    this.$alert(msg, '重置密码成功', {
      confirmButtonText: '确定',
      callback: action => {

        /*
        this.$message({
          type: 'info',
          message: `action: ${action}`
        });
        */
      }

    });

  }

  doQueryUserDataClick() {
    this.pageNum.curPage = 1;
    this.refreshTableData();
  }

  private async refreshTableData() {
    try {
      this.loadingUserList = true;
      const { data } = await UserApi.getUserList({
        keywords: this.query.keywords,
        mergeAuthSchools: true,
        curPage: this.pageNum.curPage,
        pageSize: this.pageNum.pageSize,
      });
      this.tableData = data.items;
      this.pageNum.totalPage = data.totalPage;
    } catch (error) {
      //
    } finally {
      this.loadingUserList = false;
    }
  }

  activated() {
    this.refreshTableData();
  }
  mounted() { }
}
